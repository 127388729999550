import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import Gallery from "../templates/gallery";

export const query = graphql`
  query GalleryPageQuery {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    allSanityGalleryItem(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        id
        title
        images {
          asset {
            url
          }
        }
        shopLink
        categories {
          title
          _id
        }
        description
        icon
      }
    }
  }
`;

const GalleryPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  return <Gallery data={data} />;
};

export default GalleryPage;
