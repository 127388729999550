import React from "react";

import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import GalleryMain from "../components/galleryMain";

const Gallery = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const magazinPreview = data && data?.magazinPreview?.nodes;
  const allServices = data && data?.allServices?.nodes;
  const tags = data && data?.tags?.nodes;
  const galleryData = data && data?.allSanityGalleryItem?.nodes;

  return (
    <Layout services={allServices} tags={tags} magazin={magazinPreview}>
      <SEO
        title={"Galerie"}
        description={"Galerie fotografií před a po procedurách poskytovaných na Beauty Body Clinic"}
        keywords={["Galerie", "Před a po"]}
      />
      <PageContainer>
        <GalleryMain data={galleryData} />
      </PageContainer>
    </Layout>
  );
};

export default Gallery;
